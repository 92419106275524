<script setup>
import ButtonIcon from '@/components/ui/buttons/ButtonIcon'
import VInput from '@/components/ui/form/VInput'
import VTooltip from '@/components/ui/tooltip/VTooltip'

import { defineProps, defineEmits } from 'vue'

const emit = defineEmits(['copyPosition'])

defineProps({
  formData: {
    type: Object,
    required: true
  },
  validateField: {
    type: Function,
    required: true
  },
  v$: {
    type: Object,
    required: true
  },
  serverErrors: {
    type: Object,
    required: true
  },
  isBidder: {
    type: Boolean,
    required: false
  }
})
</script>

<template>
  <div class="between mb-40">
    <div class="form__subtitle">Группы критериев</div>

    <div class="center gap-15">
      <ButtonIcon
        text="Скопировать"
        icon="Copy"
        @click="emit('copyPosition')"
      />

      <VTooltip text="Скопировать группы критериев в новую позицию"/>
    </div>
  </div>

  <div class="d-c">
    <VInput
      name="criterion_value"
      id="criterion_value"
      type="number"
      v-model="formData.criterion_value"
      :errors="v$.criterion_value.$errors"
      :server-errors="serverErrors.value?.criterion_value"
      @blur="validateField"
      @input="validateField"
      :disabled="isBidder"
      classes="select-border-bottom"
      class="max-w-400"
      placeholder="Стоимостной"
    />

    <VInput
      name="criterion_non_value"
      id="criterion_non_value"
      type="number"
      v-model="formData.criterion_non_value"
      :errors="v$.criterion_non_value.$errors"
      :server-errors="serverErrors.value?.criterion_non_value"
      @blur="validateField"
      @input="validateField"
      :disabled="isBidder"
      classes="select-border-bottom"
      class="max-w-400"
      placeholder="Нестоимостной"
    />

    <VInput
      name="criterion_skill"
      id="criterion_skill"
      type="number"
      v-model="formData.criterion_skill"
      :errors="v$.criterion_skill.$errors"
      :server-errors="serverErrors.value?.criterion_skill"
      @blur="validateField"
      @input="validateField"
      :disabled="isBidder"
      classes="select-border-bottom"
      class="max-w-400"
      placeholder="Квалификационный"
    />
  </div>
</template>

<style scoped>
.d-c {
  display: flex;
  justify-content: space-between;
}
</style>
